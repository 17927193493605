import {
  Box,
  CardHeader,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import Link from "next/link";
import { useRouter } from "next/router";

import AudienceIcon from "components/feed/AudienceIcon";
import ProfilePopoverWrapper from "components/profile/ProfilePopoverWrapper";
import Avatar from "components/shared/Avatar";
import Badge from "components/shared/Badge";
import DotSeparator from "components/ui/DotSeparator";
import useIdentity from "hooks/useIdentity";
import useMe from "hooks/useMe";
import pluralize from "lib/common/pluralize";
import { EDITABLE_ACTIVITY_VERBS } from "lib/motorcade/constants";
import {
  FeedActivity,
  GroupCollection,
  GroupType,
  StreamUser,
} from "lib/motorcade/types";
import { formatActivityTime } from "lib/motorcade/utils/date";
import { getAudienceLabel } from "lib/motorcade/utils/helpers";

import ActivityCardHeaderLoading from "./ActivityCardHeaderLoading";
import GroupJoinCTA from "./GroupJoinCTA";
import ActivityCardContextMenu from "../ActivityCardContextMenu";

type Props = {
  actionText: string;
  activity: FeedActivity;
  actors?: StreamUser[] | GroupCollection[];
  date?: string;
  hideAuthor?: boolean;
  hideAvatar?: boolean;
  hideContextMenu?: boolean;
  hideIsEdited?: boolean;
  isPinned?: boolean;
};

function HeaderAvatar({ headerActors }) {
  const localAvatar = (
    <Avatar
      alt={headerActors[0].data?.name}
      src={
        (headerActors[0]?.data?.image ||
          headerActors[0]?.data?.avatar) as string
      }
    >
      {headerActors[0].data?.name?.[0]}
    </Avatar>
  );

  if (headerActors[0].collection !== "group") {
    return ProfilePopoverWrapper(localAvatar, headerActors[0]?.id);
  }

  return localAvatar;
}

export default function ActivityCardHeader({
  actionText,
  activity,
  actors,
  date,
  hideAuthor,
  hideAvatar,
  hideContextMenu,
  hideIsEdited,
  isPinned = false,
}: Props) {
  const { id: meId } = useMe();
  const router = useRouter();
  const { role } = useIdentity();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (!meId)
    return (
      <Box p={2}>
        <ActivityCardHeaderLoading />
      </Box>
    );

  const { audience, groupRef, time, verb } = activity;
  const formattedDate = formatActivityTime(date || time);

  const joinedAt = activity?.actor?.data?.joinedAt;
  const isMemberLeader = activity?.actor?.data?.isMemberLeader;
  const isNewMember = joinedAt
    ? differenceInDays(new Date(), new Date(joinedAt)) < 30
    : false;

  const headerActors = actors || [activity.actor];
  const label = getAudienceLabel(activity);
  const isEdited =
    (activity.verb === "poll" && activity?.object?.data?.isUpdated) ||
    (activity.verb !== "poll" &&
      EDITABLE_ACTIVITY_VERBS.includes(activity.verb) &&
      activity?.object?.created_at !== activity?.object?.updated_at);

  const showGroupJoinCTA =
    role !== "ADMIN" &&
    verb !== "event" &&
    audience === "group" &&
    router.pathname !== "/groups/[slug]";

  function aggregatedAuthors() {
    const MAX_VISIBLE = 3;
    const names = headerActors
      .sort((a) => (a.id === meId ? -1 : 1))
      .map(({ collection, data, id }) => {
        let author =
          id === meId
            ? "You"
            : ProfilePopoverWrapper(data?.name, id, { as: "span" });

        if (collection === "group") author = `The ${data?.name} Group`;

        return (
          <Typography key={id} component="span" variant="body2Semibold">
            {author}
          </Typography>
        );
      });
    const visibleNames = names.slice(0, MAX_VISIBLE);
    const extra = names.length - MAX_VISIBLE;
    if (extra > 0) {
      visibleNames.push(
        <Typography component="span" variant="body2Semibold">
          {extra} {pluralize("other", extra)}
        </Typography>
      );
    }
    const last = visibleNames.pop();
    if (visibleNames.length === 0) return last;
    return (
      <>
        {visibleNames.map((visibleName, i) => (
          <>
            {i > 0 && ", "}
            {visibleName}
          </>
        ))}{" "}
        and {last}
      </>
    );
  }

  const title = (
    <Box
      alignItems={isXs || isPinned ? "start" : "center"}
      display="flex"
      flexDirection={isXs || isPinned ? "column" : "row"}
      gap={isXs || isPinned ? 0.5 : 1}
    >
      {(isMemberLeader || isNewMember) && (
        <Badge type={isMemberLeader ? "leader" : "new"} />
      )}

      <div>
        <Typography component="span" variant="body2">
          {!hideAuthor && aggregatedAuthors()} {actionText}
        </Typography>{" "}
        {label &&
          (audience === "group" ? (
            <Link
              legacyBehavior
              passHref
              href={`/groups/${encodeURIComponent(groupRef.data.slug)}`}
            >
              <Typography
                color="textPrimary"
                component="a"
                variant="body2Semibold"
              >
                {label}
              </Typography>
            </Link>
          ) : (
            <Typography component="span" variant="body2Semibold">
              {label}
            </Typography>
          ))}
      </div>
    </Box>
  );

  const subheader = (
    <Box alignItems="center" columnGap={1} display="flex" flexWrap="wrap">
      <Typography color="textSecondary" display="inline-flex" variant="caption">
        {formattedDate}
      </Typography>
      <DotSeparator />
      <AudienceIcon
        audience={audience}
        isPrivate={groupRef?.data?.type === GroupType.PRIVATE}
      />
      {isEdited && !hideIsEdited && (
        <>
          <DotSeparator />
          <Typography color="textSecondary" variant="caption">
            Edited
          </Typography>
        </>
      )}
      {showGroupJoinCTA && <GroupJoinCTA groupRef={groupRef} />}
    </Box>
  );

  return (
    <CardHeader
      action={
        !hideContextMenu && <ActivityCardContextMenu activity={activity} />
      }
      avatar={
        !hideAvatar &&
        !hideAuthor && <HeaderAvatar headerActors={headerActors} />
      }
      subheader={subheader}
      sx={{
        px: 3,
        "& .MuiCardHeader-action": {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
        },
      }}
      title={title}
    />
  );
}
