import { Theme } from "@mui/material";
import { Box, useMediaQuery } from "@mui/system";

export default function PollDeleted({ isPinned }: { isPinned: boolean }) {
  const isMdDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      mb={3}
      mx="auto"
      px={2}
      py={3}
      sx={{ backgroundColor: "grey.100" }}
      width={isMdDown || isPinned ? "100%" : "458px"}
    >
      This poll has been removed
    </Box>
  );
}
